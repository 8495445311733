/* Home */
.home-not-signed {
  .heart {
    top: 6px;
    left: 10px;
  }
  .info {
    font-size: 13px;
  }
}
/* Home */
