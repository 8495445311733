/* Wallet */
.wallet-com {
  table,
  p {
    @media (max-width: 992px) {
      width: 100% !important;
    }
  }
}
/* Wallet */
